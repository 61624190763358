<template>
  <div :class="myslh=='elder'?'old':''">
    <ul class="list">
       <li>
        <p class="title"><span class="xinhao">*</span>登记单位 </p>
        <input
          class="fill"
          type="text"
          placeholder="请选择登记单位"
          v-model="unit"
        />
        <p @click="showPopup3" class="zhedang"></p>
      </li>
      <li>
        <p class="title"><span class="xinhao"> *</span>信访人 </p>
        <input
          class="fill"
          type="text"
          placeholder="请输入信访人"
          v-model="name"
        />
      </li>
      <!-- <li>
        <p class="title">代办人</p>
        <input
          class="fill"
          type="text"
          placeholder="请输入信访人"
          v-model="db_name"
        />
      </li> -->
      <li>
        <p class="title"> <span class="xinhao"> *</span>人数</p>
        <input
          class="fill"
          type="text"
          placeholder="请输入人数"
          v-model="people"
        />
      </li>
      <li>
        <p class="title"><span class="xinhao"> *</span>证件号码 </p>
        <input
          class="fill"
          type="text"
          placeholder="请输入证件号码"
          readonly
          v-model="info.id_card"
        />
      </li>
      <li>
        <p class="title"><span class="xinhao"> *</span>电话号码 </p>
        <input
          class="fill"
          type="text"
          placeholder="请输入电话号码"
          readonly
          v-model="info.mobile"
        />
      </li>
     
      <li>
        <p class="title"><span class="xinhao"> *</span>信访日期 </p>
        <input
          class="fill"
          type="text"
          placeholder="请选择信访日期"
          v-model="s_time"
        />
        <p @click="showPopup1" class="zhedang"></p>
      </li>
      <li>
        <p class="title"><span class="xinhao"> *</span>登记日期 </p>
        <input
          class="fill"
          type="text"
          placeholder="请选择登记日期"
          v-model="e_time"
        />
        <p @click="showPopup2" class="zhedang"></p>
      </li>
      <!-- <li>
        <p class="title">信访人地址</p>
        <input
          class="fill"
          type="text"
          placeholder="请输入信访人地址"
          v-model="address"
        />
      </li>
      <li>
        <p class="title">问题发生地</p>
        <input
          class="fill"
          type="text"
          placeholder="请输入问题发生地"
          v-model="problem"
        />
        <p @click="showPopup" class="zhedang"></p>
      </li> -->
      <p class="title1"><span class="xinhao1">*</span>信访人地址</p>
        <input
          class="fill2"
          type="text"
          placeholder="请输入信访人地址"
          v-model="address"
        />
        <li style="height:89px">
          <p class="title1"><span class="xinhao"> *</span>问题发生地 </p>
          <input
            class="fill2"
            type="text"
            placeholder="请选择问题发生地"
            v-model="problem"
          />
          <p @click="showPopup" class="zhedang1"></p>
        </li>
      <p class="title1"><span class="xinhao1">*</span>主要诉求</p>
      <textarea
        type="text"
        placeholder="请输入您的主要诉求"
        v-model="appeal"
        class="fill1"
      />
    </ul>
    <div style="height: 20px"></div>
    <div class="submit">
      <button @click="from">提交</button>
    </div>
    <div style="height: 10px"></div>
    <van-popup v-model="show" position="bottom" :style="{ height: '45%' }"
      ><van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        value-key="name"
        @confirm="onConfirm"
        @cancel="onCancel"
    /></van-popup>
    <van-popup v-model="show3" position="bottom" :style="{ height: '45%' }"
      ><van-picker
        title="标题"
        show-toolbar
        :columns="columns1"
        value-key="name"
        @confirm="onConfirm3"
        @cancel="onCancel3"
    /></van-popup>
    <van-popup v-model="show1" position="bottom" :style="{ height: '45%' }"
      ><van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm1"
        @cancel="onCancel1"
    /></van-popup>
    <van-popup v-model="show2" position="bottom" :style="{ height: '45%' }"
      ><van-datetime-picker
        v-model="currentDate1"
        type="date"
        title="选择年月日"
        :min-date="minDate1"
        :max-date="maxDate1"
        @confirm="onConfirm2"
        @cancel="onCancel2"
    /></van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";
Vue.use(Popup);
import { Picker } from "vant";
Vue.use(Picker);
import { Dialog } from "vant";
// import { Toast } from "vant";
import { DatetimePicker } from "vant";
Vue.use(DatetimePicker);
import { Toast } from "vant";
Vue.use(Toast);

export default {
  data() {
    return {
      name: "",
      db_name: "",
      db_id: "",
      people: "",
      certificate: "",
      // assign: "",
      tel: "",
      unit: "",
      s_time: "",
      e_time: "",
      // serial: "",
      address: "",
      problem: "",
      problem_id: "",
      street_id:"",
      appeal: "",
      first:null,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      columns: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      columns1: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      minDate1: new Date(2020, 0, 1),
      maxDate1: new Date(2025, 10, 1),
      currentDate1: new Date(),
      utl: this.$url,
      myslh: sessionStorage.getItem('getslh'),
      info:{}
    };
  },
  mounted() {
    let db_name = this.$route.query.db_name;
    let db_id = this.$route.query.db_id;
    if (db_name) {
      this.db_name = db_name;
    }
    if (db_id) {
      this.db_id = db_id;
    }
    let that = this;
    
    this.$get("/front/street/fenji")
      .then(function (res) {
        // for (const i in res[0].street) {
        //   res[0].street[i].children = res[0].street[i].cun
        // }
        that.columns = res[0].street;
        console.log(that.columns);
      })
      .catch(function (err) {
        console.log(err);
      });
    
    this.$get("/front/street/list")
      .then(function (res) {
        // for (const i in res[0].street) {
        //   res[0].street[i].children = res[0].street[i].cun
        // }
        that.columns1 = res.list;
        that.info=res.info
         that.columns1.push({name:"椒江区信访局"})
        console.log(that.columns1);
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  methods: {
    from() {
    
      if (!this.unit) {
        Dialog({ message: "请选择登记单位" });
        return;
      }
      if (!this.name) {
        Dialog({ message: "请填写信访人名称" });
        return;
      }
      if (!this.db_name) {
        this.db_name = "暂无";
      }
      if (!this.people) {
        Dialog({ message: "请填写人数" });
        return;
      }
      // if (!this.certificate) {
      //   Dialog({ message: "请填写证件号码" });
      //   return;
      // }
      // if (!this.assign) {
      //   Dialog({ message: "请填写交办编号" });
      //   return
      // }
      // if (!(/^1[34578]\d{9}$/.test(this.tel))) {
      //   Dialog({ message: "请填写正确的电话号码" });
      //   return;
      // }
      if (!this.s_time) {
        Dialog({ message: "请选择信访日期" });
        return;
      }
      if (!this.e_time) {
        Dialog({ message: "请选择登记日期" });
        return;
      }
      // if (!this.serial) {
      //   Dialog({ message: "请填写信访件编号" });
      //   return
      // }
      if (!this.address) {
        Dialog({ message: "请填写信访人地址" });
        return;
      }
      if (!this.problem_id) {
        Dialog({ message: "请填写问题发生地" });
        return;
      }
      if (!this.appeal) {
        Dialog({ message: "请填写主要诉求" });
        return;
      }
      Dialog.confirm({
        title: '提示',
        message: '您有无在其他平台反映过此诉求？',
        confirmButtonText:'是',
        cancelButtonText:'否',
      })
      .then(() => {
        this.first = 0
        this.ajax()
      })
      .catch(() => {
        this.first = 1
        this.ajax()
      });
    },
    ajax(){
      let that = this;
      let data1 = {};
      data1.name = this.name;
      data1.agent_team = this.db_id;
      data1.people_number = this.people;
      data1.registered_unit = this.unit;
      data1.petition_date = this.s_time;
      data1.registration_date = this.e_time;
      // data1.visit_number = this.serial;
      data1.petitioner_address = this.address;
      data1.problem_happen = this.problem_id;
      data1.street_id = this.street_id 
      data1.main_appeal = this.appeal;
      data1.first = this.first;
      console.log(data1);
      // let data2 = JSON.stringify(data1);
      // console.log(data2);
      
      this.$post("/front/petition/ae", data1)
      .then(function (res) {
        console.log(res);
        if (res.status == 1) {
          Toast.success("提交成功");
          that.$router.push("/plan");
        } else {
          //  Dialog({ message: "提交失败，请检查网络" });
          Toast.fail("提交失败，请检查网络");
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    },
    start_time(date, dateString) {
      this.s_time = dateString;
      console.log(this.s_time);
    },
    end_time(date, dateString) {
      this.e_time = dateString;
      console.log(this.e_time);
    },
    showPopup() {
      this.show = true;
    },
    onConfirm(value, index) {
      // console.log(this.columns[index].id)
      this.show = false;
      this.problem = value;
      // this.problem = this.columns[index].name;
      this.street_id = this.columns[index[0]].id;
      this.problem_id = this.columns[index[0]].children[index[1]].id;
      console.log(this.problem_id)
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      this.show = false;
      // Toast("取消");
    },
    showPopup1() {
      this.show1 = true;
    },
    showPopup2() {
      this.show2 = true;
    },
     showPopup3() {
      this.show3 = true;
    },
    onConfirm1(value, index) {
      this.show1 = false;
      console.log(value);
      let xf_time =
        value.toLocaleDateString().replace(/\//g, "-") +
        " " +
        value.toTimeString().substr(0, 8);
      xf_time = xf_time.split(" ");
      console.log(xf_time);
      this.s_time = xf_time[0];
    },
    onCancel1() {
      this.show1 = false;
    },
    onConfirm2(value, index) {
      this.show2 = false;
      console.log(value);
      let xf_time =
        value.toLocaleDateString().replace(/\//g, "-") +
        " " +
        value.toTimeString().substr(0, 8);
      xf_time = xf_time.split(" ");
      console.log(xf_time);
      this.e_time = xf_time[0];
    },
    onCancel2() {
      this.show2 = false;
    },
    onConfirm3(value, index) {
      console.log(value)
      this.show3 = false;
      this.unit = value.name;
      console.log(this.unit)
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel3() {
      this.show3 = false;
    },
  },
};
</script>
<style scoped>
.list li {
  height: 50px;
  line-height: 50px;
  position: relative;
  margin-bottom: 1px;
}
.zhedang {
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  width: 100%;
}
.zhedang1 {
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  width: 100%;
}
.list li .title {
  width: 95px;
  text-align: center;
  background-color: #549ee7;
  color: white;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  float: left;
  height: 50px;
  line-height: 48px;
}

.list li .fill {
  width: calc(100% - 96px);
  margin-left: 1px;
  height: 50px;
  border: 1px solid #76c9ca;
  background-color: #f3f6fb;
  float: left;
  padding-left: 5px;
  box-sizing: border-box;
  color: #333;
  border-radius: 0px !important;
}

.title1 {
  background-color: #549ee7;
  padding-left: 10px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  height: 45px;
  line-height: 40px;
  overflow: hidden;
}

.fill1 {
  width: calc(100% - 0px);
  /* margin-left: 1px; */
  margin-top: 1px;
  padding: 5px;
  height: 150px;
  border: 1px solid #76c9ca;
  background-color: #f3f6fb;
  font-weight: 500;
  padding-left: 5px;
  box-sizing: border-box;
  color: #333;
}

::-webkit-input-placeholder {
  color: #B7B7B7;
}
.submit {
  width: 100%;
  margin-bottom: 10px;
  /* position: fixed;
  bottom: 0;
  left: 0; */
  /* height: 50px; */
}
.submit button {
  background-color: #3897d9;
  width: calc(100% - 50px);
  border-radius: 5px;
  margin-left: 25px;
  height: 45px;
  border: none;
  color: white;
  font-size: 16px;
}
.list .fill2 {
  width: 100%;
  height: 50px;
  border: 1px solid #76c9ca;
  background-color: #f3f6fb;
  padding-left: 5px;
  box-sizing: border-box;
  color: #333;
  border-radius: 0px !important;
}
.xinhao{
  color:  rgb(255 77 79);
  position: relative;
  top: 0px;
  left: -4px;
  font-weight: 500;
  font-size: 14px;
  font-family: SimSun,sans-serif;
}
.xinhao1{
  color:  rgb(255 77 79);
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: 0px;
  left: -4px;
  font-family: SimSun,sans-serif;
}



.old p{
  font-size: 18px !important;
}

.old input{
  font-size: 18px !important;
}

.old .list li .title {
  width: 120px;
}

.old .title1{
  padding-top: 3px;
  box-sizing: border-box;
}

.old .state dt{
  font-size: 18px !important;
}

.old .list li .fill{
  width: calc(100% - 121px);
}

.old .tijiao{
  font-size: 18px;
  height: 50px;
  line-height: 50px;
}

.old .fill1 {
  font-size: 18px;
} 
</style>